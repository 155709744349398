import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{
      backgroundImage: "url('/static/lettuce-bed.jpg')",
      height: "500px",
      width: "100%",
      backgroundSize: "cover"
    }}></div>
    <h1>Welcome</h1>
    <p>Jemma Farms is a market garden situated in Gibsons on the Sunshine Coast. Our goal is to provide organically vegetables on a year round basis direct to customers.</p>

    <p>In 2016, we operated on a small basis from Robert's parents land.  We were living in Vancouver and Robert would go to Gibsons to work on the farm on weekends.  We sold to customers on the Sunshine Coast and in Vancouver.</p>

    <p>In 2017, we purchased our own land in Gibsons and had a child.  Buying the land was a big step towards getting the farm going.  When time was available, the property was worked on.</p>

    <p>In 2020, work on the land progressed much more quickly.</p>

    <p>Please stay tuned for blog posts about clearing the land and preparing for growing vegetables.</p>
  </Layout>
)

export default IndexPage
